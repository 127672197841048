import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'

function SidebarNavigation(props) {
  return (
    <div className={style.sidebar}>
      <div className={style.pageList}>
        <Link className={style.pageLink} to={'/dashboard/'}>
          <strong>Dashboard</strong>
        </Link>
        <Link className={style.pageLink} to={'/pets/'}>
          <strong>My pets</strong>
        </Link>
        <Link className={style.pageLink} to={'/message/?c=doggoscom-inc'}>
          <strong>Messages</strong>
          <span className={style.messageCount}> (3)</span>
        </Link>
      </div>
    </div>
  )
}

export default SidebarNavigation
