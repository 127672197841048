import Header from '../Header'
import * as style from './style.module.scss'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import * as React from 'react'

function TemplateFullWidth(props) {
  const location = useLocation()

  return (
    <div className={style.templateFullWidth}>
      {/* Header */}
      <Header />

      {/* Site / Body */}
      <div className={style.body}>{props.children}</div>

      {/* Footer */}
      {location.pathname === '/message/' ? (
        <div className={style.messageFooterContainer}>
          <div className={style.text}>2024 © doggos™</div>
          <div className={style.linkContainer}>
            <Link to='/terms/'>Terms</Link>
            <Link to='/privacy/'>Privacy</Link>
          </div>
        </div>
      ) : (
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <div className={style.footerLinks}>
              <div>
                <h4>Cities we're in</h4>
                <ul>
                  <li>
                    <p>
                      <Link to='/search/'>Las Vegas</Link>
                      <span className={style.betaBadge}>Beta</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Phoenix <span>(Coming soon)</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className={style.companyLinks}>
                <h4>Company</h4>
                <ul>
                  <li>
                    <p>
                      <Link className={style.sitterLink} to='/about'>
                        About
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link className={style.sitterLink} to='/become-a-sitter'>
                        Become a sitter
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link className={style.sitterLink} to='/careers'>
                        Careers
                      </Link>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.footerCompanyDetails}>
              <div className={style.text}>2024 © doggos™</div>
              <div className={style.linkContainer}>
                <Link to='/terms/'>Terms</Link>
                <Link to='/privacy/'>Privacy</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TemplateFullWidth
